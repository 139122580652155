import { FC } from "react";

interface FolderIconProps {
  folderName: string;
  folderVisibility: boolean;
  isSelected: boolean;
  onClick: () => void;
}

const FolderIcon: FC<FolderIconProps> = ({
  folderName,
  folderVisibility,
  isSelected,
  onClick,
}) => {
  if (!folderVisibility) {
    return <></>;
  }

  return (
    <div
      className={isSelected ? "selectedItem" : "unSelectedItem"}
      onClick={onClick} // Directly passing the onClick handler
    >
      <img
        className="icon-big"
        src="assets/icons/folder.png"
        draggable="false"
        alt={folderName}
      />
      <span>{folderName}</span>
    </div>
  );
};

export default FolderIcon;
