import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";

export const DownloadProject = {
  Download: async (state: StateInterface) => {
    return new Promise(async (resolve, reject) => {
      // Wrap the entire logic in a new Promise
      let serverResponse;
      if (state.data) {
        const downloadProjectData = state.data;
        const url = "./php/common/downloader-proxy.php";
        axios
          .post(url, downloadProjectData, {
            responseType: "blob", // Start by assuming a binary response
          })
          .then(async (response) => {
            const contentType = response.headers["content-type"];
            if (contentType.includes("application/zip")) {
              // Handle as file download
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              const contentDisposition =
                response.headers["content-disposition"];
              let fileName = "download.zip"; // A default filename if none is specified
              if (contentDisposition) {
                const fileNameMatch =
                  contentDisposition.match(/filename="?(.*?)"?$/);
                if (fileNameMatch && fileNameMatch.length === 2)
                  fileName = fileNameMatch[1];
              }
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);

              serverResponse = "";
              resolve(serverResponse); // Resolve the promise with an empty string or appropriate success message
            } else {
              const serverResponse = await response.data.text(); // Ensure correct blob to text conversion
              resolve(serverResponse); // Resolve the promise with the response text
            }
          })
          .catch((error) => {
            console.error("Error:", error.message);
            reject("Error downloading project: " + error.message); // Reject the promise on error
          });
      } else {
        serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
        resolve(serverResponse); // Resolve the promise with an error message
      }
    });
  },
};
