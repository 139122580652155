import { Dispatch, FC, useState, useEffect } from "react";
import { ProjectContentFilterInterface } from "../../../middleware/interfaces/project/project-content-filter";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";

interface ProjectContentFilterDialogProps {
  reveal: boolean;
  user: UserInterface;
  setProjectContentfilterDialogReveal: Dispatch<React.SetStateAction<boolean>>;
  dispatch: Dispatch<any>;
}

const ProjectContentFilterDialog: FC<ProjectContentFilterDialogProps> = ({
  reveal,
  user,
  setProjectContentfilterDialogReveal,
  dispatch,
}) => {
  const [updatedProjectContentFilter, setUpdatedProjectContentFilter] =
    useState<ProjectContentFilterInterface>(() => {
      return user?.openProject?.projectContentFilter!;
    });

  useEffect(() => {
    if (user.openProject) {
      setUpdatedProjectContentFilter(user.openProject.projectContentFilter!);
    }
  }, [user.openProject]);

  const HandleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === "models" || name === "files") {
      setUpdatedProjectContentFilter((prevOptions) => ({
        ...prevOptions,
        filesAndModels: {
          ...prevOptions.filesAndModels,
          [name]: checked,
        },
      }));
    } else {
      setUpdatedProjectContentFilter((prevOptions) => ({
        ...prevOptions,
        [name]: checked,
      }));
    }
  };

  const HandleCancelClick = () => {
    setProjectContentfilterDialogReveal(false);
  };

  const HandleApplyClick = () => {
    const updatedUser = user;
    updatedUser!.openProject!.projectContentFilter =
      updatedProjectContentFilter;
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
    setProjectContentfilterDialogReveal(false);
  };

  if (!reveal) {
    return null;
  }

  return (
    <div className="dialogCover">
      <div className="dialog">
        <h2 className="dialogTitle">Project Content Filter</h2>
        <div id="ProjectContentFilterWrapper">
          <label>
            Folders
            <input
              type="checkbox"
              name="folders"
              checked={updatedProjectContentFilter.folders}
              onChange={HandleCheckboxChange}
            />
          </label>
          <label>
            Reports
            <input
              type="checkbox"
              name="reports"
              checked={updatedProjectContentFilter.reports}
              onChange={HandleCheckboxChange}
            />
          </label>
          <label>
            Models
            <input
              type="checkbox"
              name="models"
              checked={updatedProjectContentFilter.filesAndModels.models}
              onChange={HandleCheckboxChange}
            />
          </label>
          <label>
            Files
            <input
              type="checkbox"
              name="files"
              checked={updatedProjectContentFilter.filesAndModels.files}
              onChange={HandleCheckboxChange}
            />
          </label>
        </div>
        <div className="dialogButtonWrapper">
          <input
            type="button"
            name="apply"
            value="Apply"
            onClick={HandleApplyClick}
          />
          <input
            type="button"
            name="cancel"
            value="Cancel"
            onClick={HandleCancelClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectContentFilterDialog;
