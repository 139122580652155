import { FC } from "react";

interface FileIconProps {
  fileName: string;
  fileVisibility: { files: boolean; models: boolean };
  fileType: string;
  isSelected: boolean;
  onClick: () => void;
}

const FileIcon: FC<FileIconProps> = ({
  fileName,
  fileVisibility,
  fileType,
  isSelected,
  onClick,
}) => {
  const isModelFile = fileType === "ifc";
  const isVisible = isModelFile ? fileVisibility.models : fileVisibility.files;

  if (!isVisible) return <></>;

  const iconSrc = isModelFile
    ? "assets/icons/model.png"
    : "assets/icons/file.png";
  const itemClass = isSelected ? "selectedItem" : "unSelectedItem";

  return (
    <div className={itemClass} onClick={onClick}>
      <img
        className="icon-big"
        src={iconSrc}
        draggable="false"
        alt={fileName}
      />
      <span>{fileName}</span>
    </div>
  );
};

export default FileIcon;
