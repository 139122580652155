import { Dispatch, FC, useEffect, useState } from "react";
import { FileInterface } from "../../../middleware/interfaces/file/file-interface";
import FileVersionList from "./file-version-list";

interface fileVersionDialogProps {
  reveal: boolean;
  selectedFile: FileInterface;
  setSelectedFile: Dispatch<any>;
  setFileVersionDialogReveal: Dispatch<React.SetStateAction<boolean>>;
}

const FileVersionDialog: FC<fileVersionDialogProps> = ({
  reveal,
  selectedFile,
  setSelectedFile,
  setFileVersionDialogReveal,
}) => {
  const [newFileVersion, setNewFileVersion] = useState("");
  const [select, setSelect] = useState(false);

  const HandleCancelClick = () => {
    setFileVersionDialogReveal(false);
  };

  useEffect(() => {
    if (newFileVersion !== "") {
      const updatedSelectedFile = selectedFile;
      updatedSelectedFile.fileActiveVersion = +newFileVersion;
      setSelectedFile(updatedSelectedFile);
      setFileVersionDialogReveal(false);
      setSelect(false);
      setNewFileVersion("");
    }
  }, [newFileVersion]);

  if (reveal === false) {
    return null;
  }
  return (
    <div className="dialogCover">
      <div className="dialog">
        <h2 className="dialogTitle">File Versions</h2>
        <FileVersionList
          fileVersions={selectedFile.fileVersions}
          fileActiveVersion={selectedFile.fileActiveVersion}
          select={select}
          setNewFileVersion={setNewFileVersion}
        />
        <div className="dialogButtonWrapper">
          <input
            type="button"
            name="select"
            value="Select"
            onClick={() => {
              setSelect(true);
            }}
          />
          <input
            type="button"
            name="cancel"
            value="Cancel"
            onClick={HandleCancelClick}
          />
        </div>
      </div>
    </div>
  );
};

export default FileVersionDialog;
