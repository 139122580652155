import { Dispatch, FC } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import FolderIcon from "../folder/folder-icon";
import { FolderInterface } from "../../../middleware/interfaces/folder/folder-interface";
import { FileInterface } from "../../../middleware/interfaces/file/file-interface";
import FileIcon from "../file/file-icon";
import ReportIcon from "../report/report-icon";
import { ReportInterface } from "../../../middleware/interfaces/report/report-interface";

interface projectContentProps {
  user: UserInterface;
  selectedContent: FolderInterface | FileInterface | ReportInterface | null;
  setSelectedContent: Dispatch<any>;
}

const ProjectContent: FC<projectContentProps> = ({
  user,
  selectedContent,
  setSelectedContent,
}) => {
  const RenderFoldersAndFiles = () => {
    if (user.openFolder) {
      return RenderFolderChildren(
        user.openFolder.folderChildren,
        user.openFolder.folderFiles
      );
    } else if (user.openProject) {
      return RenderRootChildren();
    }
    return <p>No model available</p>;
  };

  const RenderRootChildren = () => (
    <>
      {RenderFolderChildren(
        user
          .openProject!.projectFolders.filter(
            (folder) => !folder.folderParentID
          )
          .map((folder) => folder.folderID),
        user
          .openProject!.projectFiles.filter((file) => !file.fileParentID)
          .map((file) => file.fileID)
      )}
      {RenderReportChildren(
        user.openProject!.projectReports.map((report) => report.reportID)
      )}
    </>
  );

  const RenderFolderChildren = (folderIDs: number[], fileIDs: number[]) => (
    <>
      {folderIDs.map((folderID) => {
        const folder = user.openProject!.projectFolders.find(
          (f) => f.folderID === folderID
        );
        return (
          folder && (
            <FolderIcon
              key={folder.folderID}
              folderVisibility={user.openProject!.projectContentFilter!.folders}
              folderName={folder.folderName}
              isSelected={selectedContent === folder}
              onClick={() => setSelectedContent(folder)}
            />
          )
        );
      })}
      {fileIDs.map((fileID) => {
        const file = user.openProject!.projectFiles.find(
          (f) => f.fileID === fileID
        );
        return (
          file && (
            <FileIcon
              key={file.fileID}
              fileName={file.fileName}
              fileVisibility={
                user.openProject!.projectContentFilter!.filesAndModels
              }
              fileType={file.fileType!}
              isSelected={selectedContent === file}
              onClick={() => setSelectedContent(file)}
            />
          )
        );
      })}
    </>
  );

  const RenderReportChildren = (reportIDs: number[]) => (
    <>
      {reportIDs.map((reportID) => {
        const report = user.openProject!.projectReports.find(
          (r) => r.reportID === reportID
        );
        return (
          report && (
            <ReportIcon
              key={report.reportID}
              reportName={report.reportName}
              reportVisibility={user.openProject!.projectContentFilter!.reports}
              isSelected={selectedContent === report}
              onClick={() => setSelectedContent(report)}
            />
          )
        );
      })}
    </>
  );

  return <>{RenderFoldersAndFiles()}</>;
};

export default ProjectContent;
