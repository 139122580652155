import { FC, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
  HandleLogOut,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import ResponseDialog from "../ui/user/response-dialog";
import BinaryDecisionDialog from "../ui/user/binary-decision-dialog";
import ProfileDialog from "../ui/user/profile-dialog";
import MenuButton from "../ui/user/menu-button";
import ProjectInfo from "../ui/project/project-info";
import ProjectDialog from "../ui/project/project-dialog";
import ProjectIcon from "../ui/project/project-icon";
import { ProjectInterface } from "../../middleware/interfaces/project/project-interface";
import ShareProjectDialog from "../ui/project/project-share-dialog";
import "../../styles/dashboard.css";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";
import { RetrieveReportInterface } from "../../middleware/interfaces/report/report-retrieve-interface";

export const Dashboard: FC = () => {
  const [state, dispatch] = useAppStateContext();
  const [selectedProject, setSelectedProject] =
    useState<ProjectInterface | null>(null);
  const [projectDialogReveal, setProjectDialogReveal] = useState("");
  const [projectShareDialogReveal, setProjectShareDialogReveal] =
    useState(false);
  const [profileDialogReveal, setProfileDialogReveal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    // Diselected selected item everytime user gets updated!
    setSelectedProject(null);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  useEffect(() => {
    if (state.user?.openProject) {
      if (state.user?.openReport) {
        const openReportData: RetrieveReportInterface = {
          email: state.user.email,
          reportID: state.user.openReport,
          projectID: state.user?.openProject.projectID!,
          type: "RetrieveReportInterface",
        };
        dispatch({
          type: "RETRIEVE_REPORT",
          payload: openReportData,
        });
      } else if (state.user?.openModel) {
        console.log("Model Is Open!");
      }
      navigate("/PROJECT");
    }
  }, [state.user?.openProject]);

  useEffect(() => {
    if (state.decision) {
      if (state.decision.result !== "") {
        switch (state.decision.result) {
          case "DELETE_PROJECT":
          case "DOWNLOAD_PROJECT":
          case "EDIT_PROJECT":
          case "EDIT_PROFILE":
            dispatch({
              type: state.decision.result,
              payload: state.decision.data,
            });
            break;
          case "NO_ACTION":
            console.log("No action taken!");
            break;
          default:
            // Optionally handle any cases not covered above
            console.log(`Unknown decision result: ${state.decision.result}`);
        }
        // This runs after any decision was made!
        dispatch({ type: "DECISION", decision: null });
      }
    }
  }, [state.decision?.result]);

  return (
    <>
      {(() => {
        if (state.user) {
          return (
            <div
              id="pageWrapper"
              onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
            >
              <MainNavigationPanel user={state.user} />
              <div id="contentWrapper">
                <div id="leftMenu">
                  <div className="menuButtonWrapper">
                    <MenuButton
                      imageSource="assets/icons/add-project.png"
                      alternativeText="CREATE PROJECT"
                      dispatch={() => {
                        setProjectDialogReveal("CREATE");
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/profile.png"
                      alternativeText="EDITE PROFILE"
                      dispatch={() => {
                        setProfileDialogReveal(true);
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/subscription.png"
                      alternativeText="SUBSCRIPTION"
                      dispatch={() => {
                        console.log("SUB PRESSED");
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/logout.png"
                      alternativeText="LOG OUT"
                      dispatch={() => {
                        HandleLogOut(dispatch);
                      }}
                    />
                  </div>
                </div>
                <div id="content">
                  <div
                    id="projectCanvas"
                    onClick={(e) => {
                      if (e.target === e.currentTarget) {
                        setSelectedProject(null);
                      }
                    }}
                  >
                    {state.user.projects && state.user.projects.length > 0 ? (
                      state.user.projects.map((project) => (
                        <ProjectIcon
                          projectName={project.projectName}
                          projectLocation={project.projectLocation!}
                          projectDescription={project.projectDescription!}
                          ProjectStartDate={project.projectStartDate!}
                          ProjectEndDate={project.projectEndDate!}
                          accessType={
                            project.projectOwner === state.user?.email
                              ? "OWNED"
                              : "SHARED"
                          }
                          isSelected={selectedProject === project}
                          onClick={() => {
                            setSelectedProject(project);
                          }}
                        />
                      ))
                    ) : (
                      <p>No project available</p>
                    )}
                  </div>
                  <ProjectDialog
                    reveal={projectDialogReveal}
                    user={state.user}
                    setProjectDialogReveal={setProjectDialogReveal}
                    selectedProject={selectedProject}
                    dispatch={dispatch}
                  />
                  <ProfileDialog
                    reveal={profileDialogReveal}
                    user={state.user}
                    setProfileDialogReveal={setProfileDialogReveal}
                    dispatch={dispatch}
                  />
                  <ShareProjectDialog
                    user={state.user}
                    selectedProject={selectedProject!}
                    reveal={projectShareDialogReveal}
                    setProjectShareDialogReveal={setProjectShareDialogReveal}
                    dispatch={dispatch}
                  />
                  <BinaryDecisionDialog
                    decision={state.decision}
                    dispatch={dispatch}
                  />
                  <ResponseDialog
                    title="Server Response"
                    message={state.response}
                    dispatch={dispatch}
                  />
                </div>
                <div id="rightMenu">
                  {selectedProject !== null ? (
                    <ProjectInfo
                      state={state}
                      selectedProject={selectedProject}
                      setShareProjectDialogReveal={setProjectShareDialogReveal}
                      setEditProjectDialogReveal={setProjectDialogReveal}
                      dispatch={dispatch}
                    />
                  ) : null}
                </div>
              </div>
              <MainFooterPanel />
            </div>
          );
        } else {
          return <Navigate to={"/authentication&registration"} />;
        }
      })()}
    </>
  );
};
