import { FC } from "react";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import { Navigate } from "react-router-dom";

export const MapViewer: FC = () => {
  const [state, dispatch] = useAppStateContext();
  if (state.user !== null && state.user !== undefined) {
    return <h1>MAP VIEWER</h1>;
  } else {
    return <Navigate to={"/authentication&registration"} />;
  }
};
