import { FC } from "react";

interface projectIconProps {
  projectName: string;
  projectLocation: string;
  projectDescription: string;
  ProjectStartDate: string;
  ProjectEndDate: string;
  accessType: string;
  isSelected: boolean;
  onClick: () => void;
}

const ProjectIcon: FC<projectIconProps> = ({
  projectName,
  accessType,
  isSelected,
  onClick,
}) => {
  return (
    <>
      {(() => {
        if (accessType === "OWNED") {
          return (
            <div
              className={isSelected ? "selectedItem" : "unSelectedItem"}
              onClick={(e) => {
                onClick();
              }}
            >
              <img
                className="icon-big"
                src="assets/icons/project.png"
                draggable="false"
                alt={projectName}
              />
              <span> {projectName} </span>
            </div>
          );
        } else if (accessType === "SHARED") {
          return (
            <div
              className={isSelected ? "selectedItem" : "unSelectedItem"}
              onClick={(e) => {
                onClick();
              }}
            >
              <img
                className="icon-big"
                src="assets/icons/project.png"
                draggable="false"
                alt={projectName}
              />
              <span> {projectName} </span>
            </div>
          );
        }
      })()}
    </>
  );
};

export default ProjectIcon;
