import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";

interface mainNavigationPanelProps {
  user?: UserInterface | null;
}

const MainNavigationPanel: FC<mainNavigationPanelProps> = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      <div id="navigationPanel">
        <div
          className="navigationButton"
          onClick={() => {
            navigate("/services");
          }}
        >
          <span>Services</span>
        </div>
        <div
          className="navigationButton"
          onClick={() => {
            navigate("/products");
          }}
        >
          <span>Products</span>
        </div>
        {user ? (
          <div
            className="navigationButton"
            onClick={() => {
              if (isHomePage) {
                navigate("/dashboard");
              } else {
                navigate("/");
              }
            }}
          >
            <span>{isHomePage ? "Dashboard" : "Home"}</span>
          </div>
        ) : (
          <div
            className="navigationButton"
            onClick={() => {
              if (isHomePage) {
                navigate("/authentication&registration");
              } else {
                navigate("/");
              }
            }}
          >
            <span>{isHomePage ? "Log In / Register" : "Home"}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default MainNavigationPanel;
