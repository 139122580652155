import { FC, useEffect } from "react";
import { useAppStateContext } from "../context/state-context-provider";
import { UserRegistration } from "../../core/database/user/user-registration";
import { UserAuthentication } from "../../core/database/user/user-authentication";
import { UploadFile } from "../../core/database/file/upload-file";
import { CreateProject } from "../../core/database/project/create-project";
import { DeleteProject } from "../../core/database/project/delete-project";
import { DownloadProject } from "../../core/database/project/download-project";
import { ShareProject } from "../../core/database/project/share-project";
import { DeleteFile } from "../../core/database/file/delete-file";
import { EditProfile } from "../../core/database/user/edit-profile";
import { UpdateUserWithServerResponse } from "../../helper/general/methods";
import { RecoverPassword } from "../../core/database/user/recover-password";
import { RetrieveModel } from "../../core/database/file/retrieve-model";
import { CreateFolder } from "../../core/database/folder/create-folder";
import { DeleteFolder } from "../../core/database/folder/delete-folder";
import { DownloadFolder } from "../../core/database/folder/download-folder";
import { DownloadFile } from "../../core/database/file/download-file";
import { EditProject } from "../../core/database/project/edit-project";
import { EditFolder } from "../../core/database/folder/edit-folder";
import { DeleteReport } from "../../core/database/report/delete-report";
import { DownloadReport } from "../../core/database/report/download-report";
import { RetrieveReport } from "../../core/database/report/retrieve-report";

export const CoreHandler: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    // Check if there's data to process
    if (state.data) {
      // Define a common postCall function
      const postCall = async (operation: any) => {
        dispatch({
          type: "RESPONSE",
          response: "WAIT",
        });

        let serverResponse;
        switch (operation) {
          // USER SECTION
          case "RegistrationInterface":
            serverResponse = await UserRegistration.Register(state);
            break;
          case "AuthenticationInterface":
            serverResponse = await UserAuthentication.Authenticate(state);
            break;
          case "EditProfileInterface":
            serverResponse = await EditProfile.Edit(state);
            break;
          case "RecoverPasswordInterface":
            serverResponse = await RecoverPassword.Recover(state);
            break;
          // PROJECT SECTION
          case "ProjectInterface":
            serverResponse = await CreateProject.Create(state);
            break;
          case "DeleteProjectInterface":
            serverResponse = await DeleteProject.Delete(state);
            break;
          case "DownloadProjectInterface":
            serverResponse = await DownloadProject.Download(state);
            break;
          case "ProjectShareInterface":
            serverResponse = await ShareProject.Share(state);
            break;
          case "EditProjectInterface":
            serverResponse = await EditProject.Edit(state);
            break;
          // FOLDER SECTION
          case "CreateFolderInterface":
            serverResponse = await CreateFolder.Create(state);
            break;
          case "DeleteFolderInterface":
            serverResponse = await DeleteFolder.Delete(state);
            break;
          case "EditFolderInterface":
            serverResponse = await EditFolder.Edit(state);
            break;
          case "DownloadFolderInterface":
            serverResponse = await DownloadFolder.Download(state);
            break;
          // FILE AND MODEL SECTION
          case "UploadFileInterface":
          case "UploadModelInterface":
            serverResponse = await UploadFile.Upload(state);
            break;
          case "DeleteFileInterface":
            serverResponse = await DeleteFile.Delete(state);
            break;
          case "DownloadFileInterface":
            serverResponse = await DownloadFile.Download(state);
            break;
          case "RetrieveModelInterface":
            serverResponse = await RetrieveModel.Retrieve(state);
            break;
          // REPORT SECTION
          case "DeleteReportInterface":
            serverResponse = await DeleteReport.Delete(state);
            break;
          case "DownloadReportInterface":
            serverResponse = await DownloadReport.Download(state);
            break;
          case "RetrieveReportInterface":
            serverResponse = await RetrieveReport.Retrieve(state);
            break;
          default:
            return;
        }

        dispatch({
          type: "CLEAR_DATA",
        });

        if (typeof serverResponse === "string") {
          dispatch({
            type: "RESPONSE",
            response: serverResponse,
          });
        } else if (
          typeof serverResponse === "object" &&
          serverResponse !== null
        ) {
          if (operation === "RetrieveModelInterface") {
            dispatch({
              type: "OPEN_MODEL",
              payload: serverResponse,
              response: "",
            });
          } else if (operation === "RetrieveReportInterface") {
            dispatch({
              type: "OPEN_REPORT",
              payload: serverResponse,
              response: "",
            });
          } else {
            UpdateUserWithServerResponse(serverResponse, dispatch, state.user!);
          }
        }
      };

      // Execute postCall with the current operation
      postCall(state.data.type);
    }
  }, [state.data]);

  return <></>;
};
