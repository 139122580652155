import { FC, useEffect } from "react";
import {
  CheckAuthentication,
  OpenImageInNewWindow,
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
} from "../../../helper/ui/methods";
import { useAppStateContext } from "../../../middleware/context/state-context-provider";
import MainNavigationPanel from "../../ui/user/main-navigation-panel";
import MainFooterPanel from "../../ui/user/main-footer-panel";

export const NCRP: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      CheckAuthentication(dispatch);
    }
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  return (
    <>
      {(() => {
        return (
          <div
            id="pageWrapper"
            onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
          >
            <MainNavigationPanel user={state.user} />
            <div id="contentWrapper">
              <div id="content">
                <div id="informationCanvas">
                  <h1>NavisCustomReportPro</h1>
                  <br />
                  <p>
                    NavisCustomReportPro is a user-friendly plugin that enhances
                    the functionality of the Autodesk® Navisworks® viewpoints
                    export feature by offering four major customizations.
                    <br />
                    <br />
                    First, the plugin records the location of each saved
                    viewpoint within the Navisworks "Saved Viewpoints" folder
                    structure. This feature is crucial for helping both the
                    report's authors and other users efficiently locate specific
                    viewpoints within Navisworks after the report is generated.
                    <br />
                    <br />
                    Second, it enables users to customize and adjust the
                    resolution of the exported images for each viewpoint. This
                    is particularly beneficial for detailed and complex
                    viewpoints.
                    <br />
                    <br />
                    Third, NavisCustomReportPro allows for flexible organization
                    of the final report by letting users choose the number of
                    rows and columns on each page. This optimization reduces the
                    overall number of pages and minimizes empty spaces, leading
                    to a more streamlined presentation.
                    <br />
                    <br />
                    Finally, the plugin offers the ability to distinguish
                    between saved viewpoints from different folders by altering
                    the background color of their respective containers in the
                    report. It also provides the option to exclude viewpoints
                    based on the folder name from the final report.
                    <br />
                    <br />
                    For detailed instructions on how to utilize Navis Custom
                    Report Pro effectively, please consult the accompanying
                    <a
                      className="anchor"
                      href="https://digitalcharcoal.io/pdf/instructions/ncrp/NCRP_Instruction.pdf"
                    >
                      {" "}
                      documentation
                    </a>
                    .
                  </p>
                  <div className="contentImageWrapper">
                    <img
                      src="assets/images/ncrp/ncrp_1.png"
                      alt="NavisCustomReportPro"
                      onClick={() =>
                        OpenImageInNewWindow("assets/images/ncrp/ncrp_1.png")
                      }
                    />
                    <img
                      src="assets/images/ncrp/ncrp_2.png"
                      alt="NavisCustomReportPro"
                      onClick={() =>
                        OpenImageInNewWindow("assets/images/ncrp/ncrp_2.png")
                      }
                    />
                    <img
                      src="assets/images/ncrp/ncrp_3.png"
                      alt="NavisCustomReportPro"
                      onClick={() =>
                        OpenImageInNewWindow("assets/images/ncrp/ncrp_3.png")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <MainFooterPanel />
          </div>
        );
      })()}
    </>
  );
};
