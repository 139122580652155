import { FC, useEffect } from "react";
import "../../styles/home-page.css";
import {
  CheckAuthentication,
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";

export const Home: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      CheckAuthentication(dispatch);
    }
  }, []);

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  return (
    <>
      {(() => {
        if (state.user !== null && state.user !== undefined) {
          return (
            <div
              id="pageWrapper"
              onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
            >
              <MainNavigationPanel user={state.user} />
              <div id="contentWrapper">
                <div id="content">
                  <p>PLACEHOLDER FOR THE LANDING PAGE</p>
                </div>
              </div>
              <MainFooterPanel />
            </div>
          );
        } else {
          return (
            <div id="pageWrapper">
              <MainNavigationPanel user={state.user} />
              <div id="contentWrapper">
                <div id="content">
                  <div id="informationCanvas">
                    <p>PLACEHOLDER FOR THE LANDING PAGE</p>
                  </div>
                </div>
              </div>
              <MainFooterPanel />
            </div>
          );
        }
      })()}
    </>
  );
};
