import { FC, useEffect, useRef } from "react";
import { useAppStateContext } from "../../../middleware/context/state-context-provider";

export const StandardViewport: FC = () => {
  const [state, dispatch] = useAppStateContext();
  const viewportContainerRef = useRef(null);
  useEffect(() => {
    const viewportContainer = viewportContainerRef.current;
    if (viewportContainer && state.user) {
      dispatch({ type: "START_STANDARD", payload: viewportContainer });
    }
  }, []);

  useEffect(() => {
    if (state.openModel !== undefined && state.openModel !== null) {
      if ("modelFragmentsData" in state.openModel) {
        dispatch({ type: "OPEN_FRAGMENTS" });
      } else if ("modelFileData" in state.openModel) {
        dispatch({ type: "OPEN_IFC" });
      }
    }
  }, [state.openModel]);
  return <div className="view-full-screen" ref={viewportContainerRef}></div>;
};
