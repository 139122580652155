import { StrictMode } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { StateContextProvider } from "./middleware/context/state-context-provider";
import { StandardViewer } from "./components/viewer/standard-viewer";
import { Home } from "./components/public/home-page";
import { Dashboard } from "./components/user/dashboard-page";
import { AuthenticationAndRegisteration } from "./components/user/authentication-registeration";
import "./styles/global.css";
import { FourDimensionViewer } from "./components/viewer/4d-viewer";
import { MapViewer } from "./components/viewer/map-viewer";
import { PrivacyPolicy } from "./components/public/privacy-policy";
import { NCRP } from "./components/public/product/ncrp";
import { ProjectPage } from "./components/user/project-page";
import { Products } from "./components/public/products-page";
import { AboutUs } from "./components/public/about-us-page";
import { Services } from "./components/public/services-page";
import { ContactUs } from "./components/public/contact-us-page";
import { ReportViewer } from "./components/viewer/report-viewer";

function App() {
  return (
    <StrictMode>
      <StateContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/AUTHENTICATION&REGISTRATION"
              element={<AuthenticationAndRegisteration />}
            />
            <Route path="/DASHBOARD/*" element={<Dashboard />} />
            <Route path="/PROJECT" element={<ProjectPage />} />
            <Route path="/STANDARD" element={<StandardViewer />} />
            <Route path="/4D" element={<FourDimensionViewer />} />
            <Route path="/MAP" element={<MapViewer />} />
            <Route path="/PRIVACY_POLICY" element={<PrivacyPolicy />} />
            <Route path="/ABOUT_US" element={<AboutUs />} />
            <Route path="/CONTACT_US" element={<ContactUs />} />
            <Route path="/SERVICES" element={<Services />} />
            <Route path="/PRODUCTS" element={<Products />} />
            <Route path="/PRODUCTS/NCRP" element={<NCRP />} />
            <Route path="/REPORT_VIEWER" element={<ReportViewer />} />
          </Routes>
        </Router>
      </StateContextProvider>
    </StrictMode>
  );
}

export default App;
