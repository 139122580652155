import { StandardScene } from "../../core/standard/standard-scene";

export const StandardHandler = {
  viewer: null as StandardScene | null,

  async Start(container: HTMLDivElement) {
    if (!this.viewer) {
      this.viewer = new StandardScene(container);
    }
  },

  Close() {
    if (this.viewer) {
      console.log("Standard viewer removed!");
      this.viewer.Dispose();
      this.viewer = null;
    }
  },

  async ConvertIfcToFragments(ifcModel: any) {
    if (!this.viewer) {
      throw new Error("Standard viewer is not active!");
    }
    return await this.viewer.ConvertIfcToFragments(ifcModel);
  },

  async OpenModelFragments(zipModel: any) {
    if (!this.viewer) {
      throw new Error("Standard viewer is not active!");
    }
    return await this.viewer.OpenModelFragments(zipModel);
  },
};
