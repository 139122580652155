import { FC, useEffect } from "react";
import {
  CheckAuthentication,
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";

export const AboutUs: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      CheckAuthentication(dispatch);
    }
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  return (
    <>
      {(() => {
        return (
          <div
            id="pageWrapper"
            onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
          >
            <MainNavigationPanel user={state.user} />
            <div id="contentWrapper">
              <div id="content">
                <div id="informationCanvas">
                  <h1>About Us</h1>
                  <h3>Welcome to Digital Charcoal</h3>
                  <p>
                    At Digital Charcoal, we are committed to delivering
                    excellence in AEC industry. Founded in 2023 by Arash
                    Naderpour, our mission is to [briefly state your mission or
                    goal, e.g., "make technology accessible to everyone" or
                    "provide eco-friendly solutions for everyday needs"].
                  </p>
                  <h3>Our Journey</h3>
                  <p>
                    Digital Charcoal started with [describe how the company
                    started, e.g., "a small team of passionate engineers in a
                    tiny office" or "a family recipe that became a local
                    favorite"]. Over the years, we've grown to [mention how the
                    company has grown, changes in team size, expansion in
                    business]. Throughout our journey, we have remained
                    dedicated to [reiterate mission or core values].
                  </p>
                  <h3>What We Do</h3>
                  <p>
                    At Digital Charcoal, we specialize in [describe your
                    products or services]. Our team uses [mention any special
                    technologies, methodologies, or approaches you use] to
                    ensure top-quality service and products. Each
                    [product/service] is designed with [mention what sets your
                    products apart, such as innovation, cost-effectiveness,
                    user-friendliness, etc.].
                  </p>
                  <h3>Our Team</h3>
                  <p>
                    Behind the success of Digital Charcoal is a team of
                    dedicated [professionals, artists, engineers, etc.], led by
                    [mention any key figures and their roles]. Our staff are not
                    just employees; they are passionate individuals who bring
                    creativity, expertise, and a deep commitment to [industry].
                  </p>
                  <h3>Why Choose Us?</h3>
                  <p>
                    Choosing Digital Charcoal means partnering with a company
                    that [list reasons, such as values customer relationships,
                    prioritizes sustainable practices, offers the best value,
                    etc.]. We pride ourselves on [highlight unique aspects of
                    your company culture, customer service, or products].
                  </p>
                  <h3>Our Promise</h3>
                  <p>
                    We promise to provide [mention the promise, such as
                    "unmatched quality," "innovative solutions," "excellent
                    customer support," etc.]. Your satisfaction is our priority,
                    and we work tirelessly to exceed your expectations.
                  </p>
                  <h3>Get in Touch</h3>
                  <p>
                    Join us on our mission to [repeat the mission or impact].
                    For more information about our products and services, or to
                    get started on your project, contact us at [contact
                    information] or follow us on [social media links]. We look
                    forward to building something great together.
                  </p>
                </div>
              </div>
            </div>
            <MainFooterPanel />
          </div>
        );
      })()}
    </>
  );
};
