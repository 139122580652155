import React from "react";

// Define the interface for the URLs
interface SocialMediaLinks {
  linkedin: string;
  youtube: string;
  instagram: string;
  twitter: string;
  facebook: string;
}

// Create the object with URLs
const socialMediaUrls: SocialMediaLinks = {
  linkedin: "https://www.linkedin.com/",
  youtube: "https://www.youtube.com/channel/UCz87jRndVCwtpUSwd6aVtvw",
  instagram: "https://www.instagram.com/",
  twitter: "https://www.x.com/", // Note: 'X' is the new name for Twitter
  facebook: "https://www.facebook.com/",
};

const SocialMediaLinks: React.FC = () => {
  return (
    <p>
      <a
        href={socialMediaUrls.linkedin}
        target="_blank"
        rel="noopener noreferrer"
      >
        Linkedin
      </a>
      <br />
      <a
        href={socialMediaUrls.youtube}
        target="_blank"
        rel="noopener noreferrer"
      >
        Youtube
      </a>
      <br />
      <a
        href={socialMediaUrls.instagram}
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
      <br />
      <a
        href={socialMediaUrls.twitter}
        target="_blank"
        rel="noopener noreferrer"
      >
        X (Twitter)
      </a>
      <br />
      <a
        href={socialMediaUrls.facebook}
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
    </p>
  );
};

export default SocialMediaLinks;
