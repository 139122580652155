import { FC } from "react";

interface ReportIconProps {
  reportName: string;
  reportVisibility: boolean;
  isSelected: boolean;
  onClick: () => void;
}

const ReportIcon: FC<ReportIconProps> = ({
  reportName,
  reportVisibility,
  isSelected,
  onClick,
}) => {
  if (!reportVisibility) {
    return <></>;
  }

  return (
    <div
      className={isSelected ? "selectedItem" : "unSelectedItem"}
      onClick={onClick}
    >
      <img
        className="icon-big"
        src="assets/icons/report.png"
        draggable="false"
        alt={reportName}
      />
      <span>{reportName}</span>
    </div>
  );
};

export default ReportIcon;
