import { FC, Dispatch, useEffect, useState } from "react";

interface responseDialogProps {
  title: string;
  message: string;
  dispatch: Dispatch<any>;
}

const ResponseDialog: FC<responseDialogProps> = ({
  title,
  message,
  dispatch,
}) => {
  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (ok) {
      setOk(false);
      dispatch({
        type: "RESPONSE",
        response: "",
      });
    }
  }, [ok]);

  return (
    <>
      {(() => {
        if (message !== "" && message !== undefined && message !== null) {
          if (message === "WAIT") {
            return (
              <div className="dialogCover">
                <div className="dialog">
                  <h2 className="dialogTitle">Loading</h2>
                  <h4 className="dialogMessage">Please Wait!</h4>
                </div>
              </div>
            );
          } else {
            return (
              <div className="dialogCover">
                <div className="dialog">
                  <h2 className="dialogTitle">{title}</h2>
                  <span className="dialogMessage">{message}</span>
                  <div className="dialogButtonWrapper">
                    <input
                      type="button"
                      name="ok"
                      value="Ok"
                      onClick={() => {
                        setOk(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          }
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ResponseDialog;
