import { FC, Dispatch, useState, useEffect } from "react";
import { BytesToMB, FormatDateFE } from "../../../helper/general/methods";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";
import { FileInterface } from "../../../middleware/interfaces/file/file-interface";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";

interface fileInfoProps {
  selectedFile: FileInterface;
  user: UserInterface;
  setFileVersionDialogReveal: Dispatch<React.SetStateAction<boolean>>;
  dispatch: Dispatch<any>;
}

const FileInfo: FC<fileInfoProps> = ({
  selectedFile,
  user,
  setFileVersionDialogReveal,
  dispatch,
}) => {
  const [deleteFileDialogReveal, setDeleteFileDialogReveal] = useState(false);
  const [viewerOptionsDialogReveal, setViewerOptionsDialogReveal] =
    useState(false);
  const [downloadFile, setDownloadFile] = useState(false);

  useEffect(() => {
    if (deleteFileDialogReveal) {
      const decision: DecisionInterface = {
        title: "Delete File",
        message:
          "You have selected a file to delete. Are you sure you want to continue?",
        decisionOptions: ["DELETE_FILE", "NO_ACTION"],
        result: "",
        data: {
          email: user.email,
          fileID: selectedFile.fileID,
          fileParentID: user.openFolder?.folderID! ?? null,
          fileName: selectedFile.fileName,
          fileType: selectedFile.fileType,
          projectID: user.openProject?.projectID!,
          projectUserRole: user.openProject?.projectUserRole!,
          type: "DeleteFileInterface",
        },
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDeleteFileDialogReveal(false);
  }, [deleteFileDialogReveal]);

  useEffect(() => {
    if (viewerOptionsDialogReveal) {
      const decision: DecisionInterface = {
        title: "Model Viewer",
        message:
          "Please select the model viewer enviroment from the below options.",
        decisionOptions: ["STANDARD", "4D", "MAP"],
        imageOptions: true,
        result: "",
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setViewerOptionsDialogReveal(false);
  }, [viewerOptionsDialogReveal]);

  useEffect(() => {
    if (downloadFile === true) {
      const decision: DecisionInterface = {
        title: "Download File",
        message:
          "You have selected a file to download. Are you sure you want to continue?",
        decisionOptions: ["DOWNLOAD_FILE", "NO_ACTION"],
        result: "",
        data: {
          email: user.email,
          projectUserRole: user.openProject!.projectUserRole,
          projectID: user.openProject?.projectID!,
          fileID: selectedFile.fileID,
          fileName: selectedFile.fileName,
          fileType: selectedFile.fileType,
          fileActiveVersion: selectedFile.fileActiveVersion,
          fileParentID: user.openFolder?.folderID! ?? null,
          type: "DownloadFileInterface",
        },
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDownloadFile(false);
  }, [downloadFile]);

  return (
    <>
      {(() => {
        if (selectedFile) {
          return (
            <div className="itemDetails">
              <span> Name: {selectedFile.fileName} </span>
              <span> Type: {selectedFile.fileType} </span>
              <span>
                {" "}
                Size:{" "}
                {BytesToMB(
                  selectedFile.fileVersions[selectedFile.fileActiveVersion]
                    .fileSize
                )}{" "}
              </span>
              <span>
                Version: {selectedFile.fileActiveVersion}
                {selectedFile.fileActiveVersion <
                Object.keys(selectedFile.fileVersions).length
                  ? "*"
                  : ""}
              </span>
              <span>
                {" "}
                Uploaded By:{" "}
                {
                  selectedFile.fileVersions[selectedFile.fileActiveVersion]
                    .fileUploadedBy
                }{" "}
              </span>
              <span>
                Upload Date:{" "}
                {FormatDateFE(
                  selectedFile.fileVersions[selectedFile.fileActiveVersion]
                    .fileUploadDate
                )}
              </span>
              <div className="horizontalDetailsOptionWrapper">
                {selectedFile.fileType === "ifc" ? (
                  <div className="menuButton">
                    <img
                      className="icon-small"
                      src="assets/icons/view.png"
                      alt="VIEW"
                      onClick={() => {
                        setViewerOptionsDialogReveal(true);
                      }}
                    />
                  </div>
                ) : null}
                <div className="menuButton">
                  <img
                    className="icon-small"
                    src="assets/icons/versions.png"
                    alt="VERSIONS"
                    onClick={() => {
                      setFileVersionDialogReveal(true);
                    }}
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDownloadFile(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/download.png"
                    alt="DOWNLOAD"
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDeleteFileDialogReveal(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/delete.png"
                    alt="delete"
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default FileInfo;
