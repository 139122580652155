import { FC, useEffect, useState, Dispatch } from "react";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";

interface binaryDecisionDialogProps {
  decision: DecisionInterface | null | undefined;
  dispatch: Dispatch<any>;
}

const BinaryDecisionDialog: FC<binaryDecisionDialogProps> = ({
  decision,
  dispatch,
}) => {
  const [firstOption, setFirstOption] = useState(false);
  const [secondOption, setSecondOption] = useState(false);
  useEffect(() => {
    if (decision) {
      if (firstOption) {
        decision.result = decision.decisionOptions[0];
        setFirstOption(false);
        dispatch({ type: "DECISION", decision: decision });
      }
    }
  }, [firstOption]);

  useEffect(() => {
    if (decision) {
      if (secondOption) {
        decision.result = decision.decisionOptions[1];
        setSecondOption(false);
        dispatch({ type: "DECISION", decision: decision });
      }
    }
  }, [secondOption]);

  return (
    <>
      {(() => {
        if (
          decision !== null &&
          decision !== undefined &&
          decision.decisionOptions.length === 2 &&
          decision.result === ""
        ) {
          return (
            <div className="dialogCover">
              <div className="dialog">
                <h2 className="dialogTitle">{decision.title}</h2>
                <h4 className="dialogMessage">{decision.message}</h4>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="secondDecisionOption"
                    value={"No"}
                    onClick={() => setSecondOption(true)}
                  />
                  <input
                    type="button"
                    name="firstDecisionOption"
                    value={"Yes"}
                    onClick={() => setFirstOption(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default BinaryDecisionDialog;
