import { FC, useEffect, useState, Dispatch } from "react";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";

interface viewerOptionDialogProps {
  decision: DecisionInterface | null | undefined;
  dispatch: Dispatch<any>;
}

const ViewerOptionDialog: FC<viewerOptionDialogProps> = ({
  decision,
  dispatch,
}) => {
  const [standardViewer, setStandardViewer] = useState(false);
  const [fourDimensionViewer, setFourDimensionViewer] = useState(false);
  const [mapViewer, setMapViewer] = useState(false);
  const [back, setBack] = useState(false);

  useEffect(() => {
    if (standardViewer) {
      decision!.result = "STANDARD";
      dispatch({ type: "DECISION", decision: decision });
    }
    setStandardViewer(false);
  }, [standardViewer]);

  useEffect(() => {
    if (fourDimensionViewer) {
      decision!.result = "4D";
      dispatch({ type: "DECISION", decision: decision });
    }
    setFourDimensionViewer(false);
  }, [fourDimensionViewer]);

  useEffect(() => {
    if (mapViewer) {
      decision!.result = "MAP";
      dispatch({ type: "DECISION", decision: decision });
    }
    setMapViewer(false);
  }, [mapViewer]);

  useEffect(() => {
    if (back) {
      decision = null;
      dispatch({ type: "DECISION", decision: decision });
    }
    setBack(false);
  }, [back]);

  return (
    <>
      {(() => {
        if (
          decision !== null &&
          decision !== undefined &&
          decision.decisionOptions.length > 2 &&
          decision.imageOptions === true &&
          decision.result === ""
        ) {
          return (
            <div className="dialogCover">
              <div className="dialog">
                <h2 className="dialogTitle">{decision?.title}</h2>
                <span className="dialogMessage">{decision?.message}</span>
                <div className="dialogHorizontalOptionsWrapper">
                  <div className="menuButton">
                    <img
                      className="icon-small"
                      src="assets/icons/standard-viewer.png"
                      alt="Standard Viewer"
                      onClick={() => {
                        setStandardViewer(true);
                      }}
                    />
                  </div>
                  <div className="menuButton">
                    <img
                      className="icon-small"
                      src="assets/icons/4d-viewer.png"
                      alt="4D Viewer"
                      onClick={() => {
                        setFourDimensionViewer(true);
                      }}
                    />
                  </div>
                  <div className="menuButton">
                    <img
                      className="icon-small"
                      src="assets/icons/map-viewer.png"
                      alt="Map Viewer"
                      onClick={() => {
                        setMapViewer(true);
                      }}
                    />
                  </div>
                  <div className="menuButton">
                    <img
                      className="icon-small"
                      src="assets/icons/back.png"
                      alt="Map Viewer"
                      onClick={() => {
                        setBack(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ViewerOptionDialog;
