import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface mainFooterPanelProps {}

const MainFooterPanel: FC<mainFooterPanelProps> = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <div id="footer">
        <div
          className="navigationButton"
          onClick={() => {
            navigate("/about_us");
          }}
        >
          <span>About Us</span>
        </div>
        <div
          className="navigationButton"
          onClick={() => {
            navigate("/privacy_policy");
          }}
        >
          <span>Privacy</span>
        </div>
        <div
          className="navigationButton"
          onClick={() => {
            navigate("/contact_us");
          }}
        >
          <span>Contact Us</span>
        </div>
      </div>
    </>
  );
};

export default MainFooterPanel;
