import { FC, useEffect } from "react";
import "../../styles/privacy-policy.css";
import {
  CheckAuthentication,
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";

export const PrivacyPolicy: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      CheckAuthentication(dispatch);
    }
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  return (
    <>
      {(() => {
        return (
          <div
            id="pageWrapper"
            onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
          >
            <MainNavigationPanel user={state.user} />
            <div id="contentWrapper">
              <div id="content">
                <div id="informationCanvas">
                  <h1>Digital Charcoal Privacy Statement</h1>
                  <h3>Effective date: January 1, 2024</h3>
                  <p>
                    Protecting your privacy is important to Digital Charcoal
                    ("we," "us," "our," or the "Application"). We are committed
                    to protecting your privacy and ensuring the security of your
                    personal information. This Privacy Policy is designed to
                    help you understand how we collect, use, disclose, and
                    safeguard your personal information when you use our
                    Application.
                  </p>
                  <p>
                    “Personal data” is information that identifies, or can
                    reasonably be linked directly or indirectly to, an
                    identifiable person. Personal data does not include
                    information that is anonymous, de-identified, or aggregated,
                    as those terms may be defined under applicable law. For
                    purposes of this Privacy Statement, “personal data” and
                    “personal information” have the same meaning and are used
                    interchangeably.
                  </p>
                  <h2>Information We Collect</h2>
                  <p>
                    We obtain personal data in different ways. Some personal
                    data is collected directly from you. Other data is
                    automatically collected or generated by us about how, when,
                    and why you interact with us through our offerings,
                    including data obtained through the use of cookies and other
                    tracking technologies.
                  </p>
                  <h2>Information You Provide</h2>
                  <p>
                    When you use our Application, we may collect personal
                    information that you voluntarily provide.tion we may collect
                    from you include:
                  </p>
                  <p>
                    <strong>Identifiers: </strong>Information such as your name,
                    email addresses, company name, and account password.
                  </p>
                  <p>
                    When you make purchases through our website, we use payment
                    processor (PayPal) and do not store credit card or other
                    financial information (other than confirmation that payment
                    in a certain amount has been made). We or our payment
                    processor collect contact and account information from you
                    to facilitate the transaction. For more information, you can
                    contact our provider as set forth in their privacy notices.
                  </p>
                  <h2>Automatically Collected Information</h2>
                  <p>
                    <strong>Usage Information: </strong>We collect data about
                    how you interact with our Service, such as usage time and
                    date.
                  </p>
                  <h2>How We Use Your Information</h2>
                  <p>
                    We may use the information we collect for the following
                    purposes:
                  </p>
                  <p>
                    <strong>a. </strong>To validate your identity as necessary
                    to perform our contract with you.
                  </p>
                  <p>
                    <strong>b. </strong>To send you important information, such
                    as about your account or purchases, within the scope of our
                    contract with you.
                  </p>
                  <p>
                    <strong>c. </strong>To communicate with you, respond to your
                    requests, and provide customer support.
                  </p>
                  <p>
                    <strong>d. </strong>To analyze usage patterns and improve
                    the functionality and performance of the Application.
                  </p>
                  <p>
                    <strong>e. </strong>To comply with legal obligations and
                    protect our rights and interests.
                  </p>
                  <h2>Sharing Your Information</h2>
                  <p>
                    We may disclose your information only in the following
                    circumstances:
                  </p>
                  <p>
                    <strong>Legal Obligations: </strong>We may disclose your
                    information when required by law, court order, or other
                    legal processes. This includes situations where we are
                    legally obligated to provide information to law enforcement
                    authorities or government agencies.
                  </p>
                  <h2>Security</h2>
                  <p>
                    We take reasonable measures to protect the security of your
                    personal information. However, no method of transmission
                    over the internet or electronic storage is 100% secure.
                    Therefore, we cannot guarantee the absolute security of your
                    information.
                  </p>
                  <h2>Your rights and choices</h2>
                  <p>
                    You have the right to access, update, delete, receive a copy
                    of, or restrict our use of your personal data. When we
                    process personal data based on your consent, you have the
                    right to withdraw your consent any time.
                  </p>
                  <p>
                    Please log-in to your account to manage your personal data
                    and content using our website. You can also delete your
                    personal data and close your account. We may retain certain
                    data about you for legal and internal business purposes,
                    such as fraud prevention, in accordance with applicable laws
                  </p>
                  <h2>Changes to this Privacy Policy</h2>
                  <p>
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or for other operational,
                    legal, or regulatory reasons. We will notify you of any
                    material changes by posting the updated Privacy Policy on
                    the website.
                  </p>
                  <h2>Contact Us</h2>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or our data practices, please contact us at
                    digicharcoal@gmail.com.
                  </p>
                </div>
              </div>
            </div>
            <MainFooterPanel />
          </div>
        );
      })()}
    </>
  );
};
