import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { UploadFileInterface } from "../../../middleware/interfaces/file/upload-file-interface";

export const UploadFile = {
  Upload: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      if (state.data.type === "UploadFileInterface") {
        const uploadFileData: UploadFileInterface = state.data;
        const url = "./php/file/file-uploader-proxy.php";
        await axios
          .post(url, uploadFileData)
          .then((response) => {
            serverResponse = response.data;
          })
          .catch((error) => {
            serverResponse = error.message;
          });
      } else if (state.data.type === "UploadModelInterface") {
        const uploadModelData: UploadFileInterface = state.data;
        const url = "./php/file/file-uploader-proxy.php";
        await axios
          .post(url, uploadModelData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            serverResponse = response.data;
          })
          .catch((error) => {
            serverResponse = error.message;
          });
      }
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
