import { FC, Dispatch, useEffect, useState } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { ProjectShareInterface } from "../../../middleware/interfaces/project/project-share-interface";
import { ProjectInterface } from "../../../middleware/interfaces/project/project-interface";
import { FormatDateBE } from "../../../helper/general/methods";

interface projectShareBoxProps {
  user: UserInterface;
  selectedProject: ProjectInterface;
  reveal: boolean;
  setProjectShareDialogReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const ProjectShareDialog: FC<projectShareBoxProps> = ({
  user,
  selectedProject,
  reveal,
  setProjectShareDialogReveal,
  dispatch,
}) => {
  const [startSharing, setStartSharing] = useState(false);
  const [userEmails, setUserEmails] = useState("");
  const [userRoles, setUserRoles] = useState("USER");
  useEffect(() => {
    if (startSharing) {
      const projectShareData: ProjectShareInterface = {
        projectID: selectedProject.projectID!,
        projectName: selectedProject.projectName!,
        projectAccessGrantedBy: user.email,
        projectUserRole: selectedProject.projectUserRole,
        projectNewUserEmails: userEmails,
        projectNewUserRoles: userRoles,
        projectAccessDate: FormatDateBE(new Date()),
        type: "ProjectShareInterface",
      };
      dispatch({ type: "SHARE_PROJECT", payload: projectShareData });
    }
    setStartSharing(false);
    setProjectShareDialogReveal(false);
  }, [startSharing]);

  return (
    <>
      {(() => {
        if (reveal) {
          return (
            <div className="dialogCover">
              <div id="projectShareDialog">
                <h2 className="dialogTitle">
                  Invite others to {selectedProject.projectName}
                </h2>
                <div className="dialogTextInputWrapper">
                  <textarea
                    name="people"
                    placeholder="Add people by email address..."
                    onChange={(e) => setUserEmails(e.target.value)}
                  />
                </div>
                <div id="projectShareRoleWrapper">
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="USER"
                      checked={userRoles === "USER"}
                      onChange={() => setUserRoles("USER")}
                    />
                    User
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="ADMIN"
                      checked={userRoles === "ADMIN"}
                      onChange={() => setUserRoles("ADMIN")}
                    />
                    Admin
                  </label>
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="cancelShare"
                    value="Cancel"
                    onClick={() => setProjectShareDialogReveal(false)}
                  />
                  <input
                    type="button"
                    name="startShare"
                    value="Start"
                    onClick={() => setStartSharing(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ProjectShareDialog;
