import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { CreateFolderInterface } from "../../../middleware/interfaces/folder/create-folder-interface";

export const CreateFolder = {
  Create: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const folder: CreateFolderInterface = state.data;
      const url = "./php/folder/folder-creator-proxy.php";
      await axios
        .post(url, folder)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
