import { FC, useEffect, useState, Dispatch } from "react";
import { RecoverPasswordInterface } from "../../../middleware/interfaces/user/recover-password-interface";
import { HandleInputChange } from "../../../helper/ui/methods";

interface recoverPasswordDialogProps {
  reveal: boolean;
  setRecoverPasswordDialogReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const RecoverPasswordDialog: FC<recoverPasswordDialogProps> = ({
  reveal,
  setRecoverPasswordDialogReveal,
  dispatch: dispatch,
}) => {
  const [submit, setSubmit] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [recoverPasswordData, setRecoverPasswordData] =
    useState<RecoverPasswordInterface>({
      email: "",
      type: "RecoverPasswordInterface",
    });

  useEffect(() => {
    if (submit) {
      dispatch({
        type: "RECOVER_PASSWORD",
        payload: recoverPasswordData,
      });
    }
    setSubmit(false);
    setRecoverPasswordDialogReveal(false);
  }, [submit]);

  useEffect(() => {
    if (cancel) {
      setCancel(false);
      setRecoverPasswordDialogReveal(false);
    }
  }, [cancel]);

  return (
    <>
      {(() => {
        if (reveal) {
          return (
            <div className="dialogCover">
              <div className="dialog">
                <h2 className="dialogTitle">Password Recovery</h2>
                <span className="dialogMessage">
                  Please enter your email address below to recover your
                  password.
                </span>
                <div className="dialogValueWrapper">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => {
                      HandleInputChange(e, setRecoverPasswordData);
                    }}
                  />
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="submit"
                    value="Submit"
                    onClick={() => {
                      setSubmit(true);
                    }}
                  />
                  <input
                    type="button"
                    name="cancel"
                    value="Cancel"
                    onClick={() => {
                      setCancel(true);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default RecoverPasswordDialog;
