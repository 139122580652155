import { Dispatch, useEffect, useState } from "react";
import { FileVersionInterface } from "../../../middleware/interfaces/file/file-version-interface";
import { BytesToMB, FormatDateFE } from "../../../helper/general/methods";

type FileVersionListProps = {
  fileVersions: { [key: string]: FileVersionInterface };
  fileActiveVersion: number;
  select: boolean;
  setNewFileVersion: Dispatch<React.SetStateAction<string>>;
};

const FileVersionList: React.FC<FileVersionListProps> = ({
  fileVersions,
  fileActiveVersion,
  select,
  setNewFileVersion,
}) => {
  const [selectedFileVersion, setSelectedFileVersion] = useState("");

  useEffect(() => {
    if (select) {
      setNewFileVersion(selectedFileVersion);
    }
  }, [select]);

  useEffect(() => {
    setSelectedFileVersion(fileActiveVersion.toString());
  }, []);

  return (
    <div className="dialogValueListWrapper">
      {Object.entries(fileVersions).map(
        ([key, { fileSize, fileUploadDate, fileUploadedBy }]) => (
          <div key={key} className="fileVersionWrapper">
            <input
              type="radio"
              name="fileVersion"
              value={key}
              checked={key === selectedFileVersion}
              onChange={() => setSelectedFileVersion(key)}
            />
            <span>{FormatDateFE(fileUploadDate)}</span>
            <span>{BytesToMB(fileSize)}</span>
            <span>{fileUploadedBy}</span>
          </div>
        )
      )}
    </div>
  );
};

export default FileVersionList;
