import JSZip from "jszip";
import * as OBC from "openbim-components";
import * as THREE from "three";
import { Base64ToUint8Array, FileToBase64 } from "../../helper/general/methods";

export class StandardScene {
  private components: OBC.Components;
  private fragments: OBC.FragmentManager;
  private fragmentIfcLoader: OBC.FragmentIfcLoader;
  private highlighter: OBC.FragmentHighlighter;
  private scene: THREE.Scene;
  public zipModelFragments: any;

  constructor(container: HTMLDivElement) {
    this.components = new OBC.Components();

    const sceneComponent = new OBC.SimpleScene(this.components);
    sceneComponent.setup();
    this.scene = sceneComponent.get();
    this.scene.background = null;
    this.components.scene = sceneComponent;

    // const directionalLight = new THREE.DirectionalLight();
    // directionalLight.position.set(5, 10, 3);
    // directionalLight.intensity = 0.5;
    // this.scene.add(directionalLight);

    // const ambientLight = new THREE.AmbientLight();
    // ambientLight.intensity = 0.5;
    // this.scene.add(ambientLight);

    const rendererComponent = new OBC.PostproductionRenderer(
      this.components,
      container
    );
    this.components.renderer = rendererComponent;

    const raycasterComponent = new OBC.SimpleRaycaster(this.components);
    this.components.raycaster = raycasterComponent;

    const orthoCamera = new OBC.OrthoPerspectiveCamera(this.components);
    // const simpleCamera = new OBC.SimpleCamera(this.components);
    this.components.camera = orthoCamera;

    const grid = new OBC.SimpleGrid(this.components);

    this.components.init();

    rendererComponent.postproduction.enabled = true;

    const mainToolbar = new OBC.Toolbar(this.components);
    mainToolbar.name = "Main toolbar";
    this.components.ui.addToolbar(mainToolbar);

    const alertButton = new OBC.Button(this.components);
    alertButton.materialIcon = "info";
    alertButton.tooltip = "Information";
    mainToolbar.addChild(alertButton);
    alertButton.onClick.add(() => {
      alert("I've been clicked!");
    });

    this.fragments = new OBC.FragmentManager(this.components);

    this.highlighter = new OBC.FragmentHighlighter(this.components);

    this.fragments.onFragmentsLoaded.add(() => {
      this.highlighter.setup();
    });

    this.fragmentIfcLoader = new OBC.FragmentIfcLoader(this.components);
    this.fragmentIfcLoader.onIfcLoaded.add(() => {
      this.highlighter.setup();
    });
  }

  Dispose() {
    this.components.dispose();
    (this.components as any) = null;
    (this.fragments as any) = null;
  }

  async ConvertIfcToFragments(ifcModel: any) {
    try {
      // this.SetupLoader();
      this.fragmentIfcLoader.settings.wasm = {
        path: "https://unpkg.com/web-ifc/",
        absolute: true,
      };

      this.fragmentIfcLoader.settings.webIfc = {
        COORDINATE_TO_ORIGIN: true,
        OPTIMIZE_PROFILES: true,
      };

      const uint8Array = Base64ToUint8Array(ifcModel["modelFileData"]);

      // model: Promise <FragmentsGroup>
      const model = await this.fragmentIfcLoader.load(
        uint8Array,
        ifcModel["modelName"]
      );

      this.scene.add(model);

      return await this.CreateZipModelFragments(
        ifcModel["modelName"],
        this.fragments
      );
    } catch (error: any) {
      // Handle the error, e.g., log or perform other error-handling actions
      console.error(`An error occurred: ${error.message}`);
    }
  }

  async CreateZipModelFragments(
    modelName: string,
    modelFragments: OBC.FragmentManager
  ) {
    if (!modelFragments.groups.length) {
      return;
    }
    const group = modelFragments.groups[0];
    const data = modelFragments.export(group);
    // Create a new JSZip instance
    const zip = new JSZip();
    // Add the fragment file
    zip.file("model.frag", data);
    // Add the model.json file
    zip.file("model.json", JSON.stringify(group.getLocalProperties()));
    // Generate the zip file
    const zipFile = await zip.generateAsync({ type: "blob" });
    const modelSize = zipFile.size;
    // Convert the zip file to base64
    const modelData = await FileToBase64(zipFile);
    const result = {
      modelName: modelName,
      modelFragmentsData: modelData,
      modelFragmentsSize: modelSize,
    };
    return result;
  }

  async OpenModelFragments(zipModel: any) {
    try {
      const files = await this.UnzipModelFragments(
        zipModel["modelFragmentsData"]
      );
      // Now you can do something with the unzipped files
      if (files[0].relativePath.includes(".frag")) {
        files[0].content
          .then(async (content: ArrayBuffer) => {
            if (!(content instanceof ArrayBuffer)) {
              return;
            }
            const buffer = new Uint8Array(content);
            //this.fragments.load(buffer) returns model = Promise<FragmentsGroup>
            await this.fragments.load(buffer).then(async (model) => {
              files[1].content
                .then(async (content: ArrayBuffer) => {
                  const properties = new TextDecoder().decode(content);
                  model.setLocalProperties(JSON.parse(properties));
                  this.highlighter.updateHighlight();
                })
                .catch((error: any) => {
                  console.log(error);
                });
            });
          })
          .catch((error: any) => {
            // Handle errors if the promise is rejected
            console.error("Error fetching file content:", error);
          });
      } else {
        files[1].content
          .then(async (content: any) => {
            if (!(content instanceof ArrayBuffer)) {
              return;
            }
            const buffer = new Uint8Array(content);
            //this.fragments.load(buffer) returns model = Promise<FragmentsGroup>
            await this.fragments.load(buffer).then(async (model) => {
              files[0].content
                .then(async (content: ArrayBuffer) => {
                  const properties = new TextDecoder().decode(content);
                  model.setLocalProperties(JSON.parse(properties));
                  this.highlighter.updateHighlight();
                })
                .catch((error: any) => {
                  console.log(error);
                });
            });
          })
          .catch((error: any) => {
            // Handle errors if the promise is rejected
            console.error("Error fetching file content:", error);
          });
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  }

  async UnzipModelFragments(zipModelData: string): Promise<any[]> {
    try {
      const zip = await JSZip.loadAsync(zipModelData, { base64: true });
      const files: any[] = [];

      zip.forEach((relativePath, file) => {
        files.push({ relativePath, content: file.async("arraybuffer") });
      });
      return files;
    } catch (error) {
      console.error("Error in UnzipModelFragments:", error);
      throw error;
    }
  }

  async OnModelLoaded(model: any, highlighter: OBC.FragmentHighlighter) {}
}
