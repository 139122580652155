import { FC, useEffect } from "react";
import "../../styles/components.css";
import "../../styles/standard-viewer.css";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import { Navigate } from "react-router-dom";
import {
  CheckAuthentication,
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import ResponseDialog from "../ui/user/response-dialog";
import { StandardViewport } from "../ui/view/standard-viewport";
import { FormatDateBE } from "../../helper/general/methods";
import { UploadModelInterface } from "../../middleware/interfaces/file/upload-model-interface";

export const StandardViewer: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      CheckAuthentication(dispatch);
    }
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
      dispatch({ type: "CLOSE_STANDARD", payload: null });
    };
  }, []);

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  useEffect(() => {
    //PREPARE AND UPLOAD MODEL FRAGMENTS
    if (
      state.data !== null &&
      typeof state.data === "object" &&
      typeof state.data.then === "function"
    ) {
      state.data
        .then((result: any) => {
          const uploadModel: UploadModelInterface = {
            email: state.user?.email!,
            projectID: state.user?.openProject?.projectID!,
            projectUserRole: state.user?.openProject?.projectUserRole!,
            modelFileParentID: state.user?.openFolder
              ? state.user?.openFolder.folderID
              : null,
            modelName: result["modelName"],
            modelFileType: "ifc",
            modelFileSize: state.openModel["modelFileSize"],
            modelFragmentsSize: result["modelFragmentsSize"],
            modelUploadDate: FormatDateBE(new Date()),
            modelData: {
              modelFragmentsData: result["modelFragmentsData"],
              modelFileData: state.openModel["modelFileData"],
            },
            type: "UploadModelInterface",
          };
          dispatch({ type: "UPLOAD_FILE", payload: uploadModel });
        })
        .catch((error: string) => {
          console.error("Promise rejected:", error);
        });
    } else {
    }
  }, [state.data]);

  return (
    <>
      {(() => {
        if (state.user !== null && state.user !== undefined) {
          return (
            <div
              id="pageWrapper"
              onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
            >
              <div id="pageHeader">
                <input id="openFile" type="file" />
                <input id="addFile" type="file" />
                <input id="exportIFC" type="file" />
                {/* <div id="text-container">
                  <p>Progress:</p>
                  <p id="progress-text">0</p>
                  <p>%</p>
                </div> */}
              </div>
              <div id="leftMenuWrapper">
                <div id="buttonMenu">
                  <div id="leftMenuButtonWrapper">
                    <div className="leftMenuButtons">
                      <img src="assets/layersButton.ico" alt="LAYERS" />
                    </div>
                    <div className="leftMenuButtons">
                      <img
                        src="assets/showPropertiesButton.ico"
                        alt="PROPERTIES"
                      />
                    </div>
                    <div className="leftMenuButtons">
                      <img src="assets/editPropertiesButton.ico" alt="EDIT" />
                    </div>
                    <div className="leftMenuButtons">
                      <img src="assets/layersButton.png" alt="N/A" />
                    </div>
                    <div className="leftMenuButtons">
                      <img src="assets/layersButton.png" alt="N/A" />
                    </div>
                  </div>
                </div>
                <div id="layerMenu"></div>

                <div id="propertiesMenu">
                  <div id="propertiesWrapper"></div>
                </div>
              </div>
              <StandardViewport />
              {/* <div className="viewWrapper">
                <StandardViewport />
              </div> */}
              <ResponseDialog
                title="Server Response"
                message={state.response}
                dispatch={dispatch}
              />
              <div id="pageFooter"></div>
            </div>
          );
        } else {
          if (state.response !== "") {
            <div
              id="content"
              onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
            >
              <ResponseDialog
                title="Server Response"
                message={state.response}
                dispatch={dispatch}
              />
            </div>;
          } else {
            return <Navigate to={"/authentication&registration"} />;
          }
        }
      })()}
    </>
  );
};
