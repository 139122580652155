import { Action } from "../context/actions";
import { StateInterface } from "../interfaces/user/state-interface";
import { StandardHandler } from "./standard-handler";

export const reducer = (state: StateInterface, action: Action) => {
  switch (action.type) {
    case "CLEAR_DATA":
      return {
        ...state,
        data: null,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
        response: action.response,
      };
    case "LOGOUT":
      return {
        ...state,
        user: action.payload,
        openReport: action.payload,
        openModel: action.payload,
        response: "",
      };
    case "AUTHENTICATE":
      return { ...state, data: action.payload };
    case "REGISTER":
      return { ...state, data: action.payload };
    case "SIGNUP":
      return {
        ...state,
        response: action.response,
      };
    case "EDIT_PROFILE":
      return { ...state, data: action.payload };
    case "RECOVER_PASSWORD":
      return { ...state, data: action.payload };
    // PROJECT DISPATCHER
    case "CREATE_PROJECT":
      return { ...state, data: action.payload };
    case "DELETE_PROJECT":
      return { ...state, data: action.payload };
    case "DOWNLOAD_PROJECT":
      return { ...state, data: action.payload };
    case "EDIT_PROJECT":
      return { ...state, data: action.payload };
    case "CREATE_FOLDER":
      return { ...state, data: action.payload };
    case "DELETE_FOLDER":
      return { ...state, data: action.payload };
    case "EDIT_FOLDER":
      return { ...state, data: action.payload };
    case "DOWNLOAD_FOLDER":
      return { ...state, data: action.payload };
    // MODEL DISPATCHER
    case "UPLOAD_FILE":
      return { ...state, data: action.payload };
    case "DELETE_FILE":
      return { ...state, data: action.payload };
    case "DOWNLOAD_FILE":
      return { ...state, data: action.payload };
    case "RETRIEVE_MODEL":
      return { ...state, data: action.payload };
    case "SHARE_PROJECT":
      return { ...state, data: action.payload };
    case "DELETE_MODEL":
      return { ...state, data: action.payload };
    case "OPEN_MODEL":
      return {
        ...state,
        openModel: action.payload,
        response: action.response,
      };
    case "DOWNLOAD_REPORT":
      return { ...state, data: action.payload };
    case "DELETE_REPORT":
      return { ...state, data: action.payload };
    case "RETRIEVE_REPORT":
      return { ...state, data: action.payload };
    case "OPEN_REPORT":
      return {
        ...state,
        openReport: action.payload,
        response: action.response,
      };
    case "CLOSE_REPORT":
      return {
        ...state,
        openReport: null,
      };
    case "FILTER_REPORT":
      return {
        ...state,
        openReport: action.payload,
      };
    // UI DISPATCHER
    case "DECISION":
      return { ...state, decision: action.decision };
    case "RESPONSE":
      return {
        ...state,
        response: action.response,
      };
    // VIEWPORT DISPATCHER
    case "START_STANDARD":
      StandardHandler.Start(action.payload);
      return state;
    case "OPEN_IFC":
      const zipModelFragments = StandardHandler.ConvertIfcToFragments(
        state.openModel
      );
      // this triggers an event in standard-viewer.tsx not coreHandler.tsx
      return { ...state, data: zipModelFragments };
    case "OPEN_FRAGMENTS":
      StandardHandler.OpenModelFragments(state.openModel);
      return state;
    case "CLOSE_STANDARD":
      StandardHandler.Close();
      return { ...state, openModel: null };
    //THROW ERROR
    default:
      throw new Error();
  }
};
