import { FC, Dispatch } from "react";

interface viewerOptionDialogProps {
  imageSource: string;
  alternativeText: string;
  dispatch: Dispatch<any>;
}

const MenuButton: FC<viewerOptionDialogProps> = ({
  imageSource,
  alternativeText,
  dispatch,
}) => {
  return (
    <>
      <div className="menuButton" onClick={dispatch}>
        <img
          className="icon-big"
          src={imageSource}
          draggable="false"
          alt={alternativeText}
        />
      </div>
    </>
  );
};

export default MenuButton;
