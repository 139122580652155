import { FC, Dispatch, useState, useEffect } from "react";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";
import { ProjectInterface } from "../../../middleware/interfaces/project/project-interface";
import { FormatDateFE } from "../../../helper/general/methods";
import { DeleteProjectInterface } from "../../../middleware/interfaces/project/project-delete-interface";
import { DownloadProjectInterface } from "../../../middleware/interfaces/project/project-download-interface";

interface projectInfoProps {
  state: StateInterface;
  selectedProject: ProjectInterface;
  setShareProjectDialogReveal: Dispatch<any>;
  setEditProjectDialogReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const ProjectInfo: FC<projectInfoProps> = ({
  state,
  selectedProject,
  setShareProjectDialogReveal,
  setEditProjectDialogReveal,
  dispatch,
}) => {
  const [openProject, setOpenProject] = useState(false);
  const [deleteProjectDialogReveal, setDeleteProjectDialogReveal] =
    useState(false);
  const [downloadProject, setDownloadProject] = useState(false);
  const [editProject, setEditProject] = useState(false);

  useEffect(() => {
    if (openProject === true) {
      const updatedUser = state.user;
      updatedUser!.openProject = selectedProject;
      dispatch({
        type: "UPDATE_USER",
        payload: updatedUser,
        response: "",
      });
      setOpenProject(false);
    }
  }, [openProject]);

  useEffect(() => {
    if (deleteProjectDialogReveal) {
      const deleteProjectData: DeleteProjectInterface = {
        email: state.user?.email!,
        projectID: selectedProject!.projectID!,
        projectUserRole: selectedProject!.projectUserRole,
        type: "DeleteProjectInterface",
      };
      const decision: DecisionInterface = {
        title: "Delete Project",
        message:
          "You have selected a project to delete. Are you sure you want to continue?",
        decisionOptions: ["DELETE_PROJECT", "NO_ACTION"],
        result: "",
        data: deleteProjectData,
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDeleteProjectDialogReveal(false);
  }, [deleteProjectDialogReveal]);

  useEffect(() => {
    if (downloadProject === true) {
      const downloadProjectData: DownloadProjectInterface = {
        email: state.user!.email,
        projectID: selectedProject!.projectID!,
        projectUserRole: selectedProject!.projectUserRole,
        type: "DownloadProjectInterface",
      };
      const decision: DecisionInterface = {
        title: "Download Project",
        message:
          "You have selected a project to download. Are you sure you want to continue?",
        decisionOptions: ["DOWNLOAD_PROJECT", "NO_ACTION"],
        result: "",
        data: downloadProjectData,
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDownloadProject(false);
  }, [downloadProject]);

  useEffect(() => {
    if (editProject === true) {
      setEditProjectDialogReveal("EDIT");
    }
    setEditProject(false);
  }, [editProject]);

  return (
    <>
      {(() => {
        if (selectedProject) {
          return (
            <div className="itemDetails">
              <span> Project: {selectedProject.projectName} </span>
              <span> Location: {selectedProject.projectLocation} </span>
              <span>
                Start Date: {FormatDateFE(selectedProject.projectStartDate!)}
              </span>
              <span>
                End Date: {FormatDateFE(selectedProject.projectEndDate!)}
              </span>
              <span>
                Created: {FormatDateFE(selectedProject.projectCreationDate!)}
              </span>
              <span> Role: {selectedProject.projectUserRole} </span>
              <span> Owner: {selectedProject.projectOwner} </span>
              <span> Description: {selectedProject.projectDescription} </span>
              <div className="horizontalDetailsOptionWrapper">
                <div className="menuButton">
                  <img
                    className="icon-small"
                    src="assets/icons/enter.png"
                    alt="OPEN"
                    onClick={() => {
                      setOpenProject(true);
                    }}
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setShareProjectDialogReveal(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/share.png"
                    alt="SHARE"
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDownloadProject(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/download.png"
                    alt="DOWNLOAD"
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setEditProject(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/edit.png"
                    alt="EDIT"
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDeleteProjectDialogReveal(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/delete.png"
                    alt="DELETE"
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ProjectInfo;
