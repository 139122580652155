import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { RetrieveReportInterface } from "../../../middleware/interfaces/report/report-retrieve-interface";

export const RetrieveReport = {
  Retrieve: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const retrieveReportData: RetrieveReportInterface = state.data;
      const url = "./php/report/report-retriever-proxy.php";
      await axios
        .post(url, retrieveReportData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }
    return serverResponse;
  },
};
